require("es6-promise").polyfill();

import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import router from "./router";

import ui from "@ddss/ui/library/defence.library.esm";

Vue.use(ui);
Vue.use(VueRouter);

var app = new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event("render-event"));
//    let ddssScripts = document.createElement('script')
//    ddssScripts.setAttribute('src', '/sps/static/js/ddssoverrides.js');
//    document.head.appendChild(ddssScripts);
//    let ddssFooter = document.createElement('script');
//    ddssFooter.setAttribute('src','/sps/static/js/ddssfooters.js');
//    document.body.appendChild(ddssFooter);
  //  let srcscript = document.querySelector('script[src="/build.js"]');
  //  srcscript.getParent().removeChild(srcscript);
  },
});

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./Open_Sans/OpenSans-VariableFont_wdth_wght.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./Open_Sans/OpenSans-Italic-VariableFont_wdth_wght.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./Titillium_Web/TitilliumWeb-Regular.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./Titillium_Web/TitilliumWeb-SemiBold.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./Titillium_Web/TitilliumWeb-Bold.ttf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-stretch: 100%;\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"ttf\");\n}\n@font-face {\n  font-family: \"Open Sans\";\n  font-style: italic;\n  font-weight: 400;\n  font-stretch: 100%;\n  src: url(" + ___CSS_LOADER_URL___1___ + ")\n    format(\"ttf\");\n}\n@font-face {\n  font-family: \"Titillium Web\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"ttf\")\n    format(\"ttf\");\n}\n\n@font-face {\n  font-family: \"Titillium Web\";\n  font-style: normal;\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL___3___ + ") format(\"ttf\")\n    format(\"ttf\");\n}\n@font-face {\n  font-family: \"Titillium Web\";\n  font-style: normal;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL___4___ + ") format(\"ttf\") format(\"ttf\");\n}\n", ""]);


import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/faq",
    name: "FAQ",
    // route level code-splitting
    // this generates a separate chunk (faq.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/login-err",
    name: "Login-err",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login-error.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Logout.vue"),
  },
  {
    path: "/loginsmsauth",
    name: "LoginAuth",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/LoginSmsAuth.vue"
      ),
  },
  {
    path: "/systemerr",
    name: "SystemErr",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/errors/SystemErr.vue"
      ),
  },
  {
    path: "/macotperror",
    name: "MACOTPErr",
    component: () =>
      import(
        "../views/errors/MACOTPErr.vue"
       ),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/errors/404.vue"
      ),
  },
  {
    path: "/activation/expired",
    name: "Expired",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate/LinkExpired.vue"
      ),
  },
  {
    path: "/activation/activated",
    name: "SMS-Activated",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate/Activated.vue"
      ),
  },

  {
    path: "/password-reset/request",
    name: "Request",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/password-reset/Request.vue"
      ),
  },
  {
    path: "/password-reset/sms-auth",
    name: "Password-SMS Auth",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/password-reset/SMS-Auth.vue"
      ),
  },
  {
    path: "/password-reset/sec-questions",
    name: "Sec Questions",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/password-reset/Sec-Questions.vue"
      ),
  },
  {
    path: "/password-reset/reset-password",
    name: "Reset Password",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/password-reset/Reset-Password.vue"
      ),
  },
  {
    path: "/password-reset/success",
    name: "Password Success",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/password-reset/Success.vue"
      ),
  },

  {
    path: "/username-recovery/provide-email",
    name: "Provide Email",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/username-recovery/Provide-Email.vue"
      ),
  },
  {
    path: "/username-recovery/success",
    name: "Username Success",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/username-recovery/Success.vue"
      ),
  },

  {
    path: "/activate-account/personal-details",
    name: "Personal Details",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/Personal-Details.vue"
      ),
  },
  {
    path: "/activate-account/sms-auth",
    name: "Activate-SMS Auth",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/SMS-Auth.vue"
      ),
  },
  {
    path: "/activate-account/security-questions",
    name: "Security Questions",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/Security-Questions.vue"
      ),
  },
  {
    path: "/activate-account/agreement",
    name: "Account Agreement",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/Agreement.vue"
      ),
  },
  {
    path: "/activate-account/agreement-heritage",
    name: "Account Agreement - Heritage",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/Agreement-Heritage.vue"
      ),
  },
  {
    path: "/activate-account/activated",
    name: "Account-Activated",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/Activated.vue"
      ),
  },

  {
    path: "/security-questions/edit",
    name: "Edit",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/update-security-questions/Edit.vue"
      ),
  },
  {
    path: "/security-questions/sms-auth",
    name: "Questions-SMS Auth",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/update-security-questions/SMS-Auth.vue"
      ),
  },
  {
    path: "/security-questions/success",
    name: "Questions-Success",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/update-security-questions/Success.vue"
      ),
  },

  {
    path: "/account/my-account",
    name: "My Account ADO-P",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/account/My-Account-ADO-P.vue"
      ),
  },
  {
    path: "/account/my-account-heritage",
    name: "My Account Heritage",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/account/My-Account-Heritage.vue"
      ),
  },
  {
    path: "/account/agreement",
    name: "OSD Agreement",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/account/OSD-Agreement.vue"
      ),
  },


  {
    path: "/change-password/change",
    name: "Change Password",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/change-password/Reset-Password.vue"
      ),
  },
  {
    path: "/change-password/sms-auth",
    name: "Change Password-SMS Auth",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/change-password/SMS-Auth.vue"
      ),
  },
  {
    path: "/change-password/success",
    name: "Change Password-Success",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/change-password/Success.vue"
      ),
  },



  {
    path: "/account/_master",
    name: "account_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/account/_master.vue"
      ),
  },
  {
    path: "/activate/_master",
    name: "activate_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate/_master.vue"
      ),
  },
  {
    path: "/activate-account/_master",
    name: "activate-account_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/activate-account/_master.vue"
      ),
  },
  {
    path: "/change-password/_master",
    name: "change-password_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/change-password/_master.vue"
      ),
  },
  {
    path: "/errors/_master",
    name: "errors_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/errors/_master.vue"
      ),
  },
  {
    path: "/password-reset/_master",
    name: "password-reset_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/password-reset/_master.vue"
      ),
  },
  {
    path: "/update-security-questions/_master",
    name: "update-security-questions_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/update-security-questions/_master.vue"
      ),
  },
  {
    path: "/username-recovery/_master",
    name: "username-recovery_master",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/username-recovery/_master.vue"
      ),
  },
  {
    path: "/username-recovery/error-email",
    name: "username-recovery_error-email",
    component: () =>
      import(
        /* webpackChunkName: "loginsmsauth" */ "../views/username-recovery/Error-Email.vue"
      ),
  },



];


// These are what get rendered to the app bar navigation
export const menuItems = [
  { title: "Login", link: "/login" },
  { title: "FAQ", link: "/faq" },
];

const oldMenuItems = routes.map((route) => {
  return { title: route.name, link: route.path };
});

const router = new VueRouter({
  mode: "history",
  base: "",
  routes,
});

export default router;

<template>
  <def-application-container>
    <def-navigation-global
      theme="theme-light"
      :has-search="false"
      ministers-link="https://www.minister.defence.gov.au/"
      navy-link="https://www.navy.gov.au/"
      army-link="https://www.army.gov.au/"
      airforce-link="https://www.airforce.gov.au/"
    ></def-navigation-global>
    <def-header theme="theme-light" :items-primary="this.routes"></def-header>
    <router-view></router-view>
    <def-footer-global
      social-link="https://www.defence2.gov.au/"
      acknowledgement-link="https://www.defence.gov.au/"
      :items="items"
      :menu-items="footerItems"
    ></def-footer-global>
  </def-application-container>
</template>

<script>
import "./assets/theme.min.css";
import "./assets/google_fonts/google_fonts.css";
import "@fortawesome/fontawesome-free/css/all.css";

function isLinkActive(item, activeUrl) {
  return { ...item, active: item.link === activeUrl };
}

import { menuItems } from "./router/index";
const footerItems = require(/* webpackChunkName: "footer-items" */
"./assets/footer-items.json");

export default {
  name: "DOSDApp",
  data() {
    return {
      currentRoute: window.location.pathname,
      footerItems: footerItems.items,
    };
  },
  computed: {
    routes() {
      if (!this.currentRoute) return [];
      const routesList = menuItems.map((item) =>
        isLinkActive(item, this.currentRoute)
      );
      return routesList;
    },
  },
 mounted(){
  document.querySelector(".dod-footer-explore").addEventListener('click', function(e) {e.preventDefault(); 
    document.querySelector(".dod-footer-explore").classList.toggle("explore-open"); 
    document.querySelector("#collapseExplore").classList.toggle("show");
    if (document.querySelector("#collapseExplore").style.height == "0px") { 
       document.querySelector("#collapseExplore").style.height = "221px";
    } else {
       document.querySelector("#collapseExplore").style.height = "0px";
    }
  });
  },
};
</script>
